
import Vue from 'vue';
import { Watch, Component } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { TranslateResult } from 'vue-i18n';
import to from 'await-to-js';
import debounce from 'lodash/debounce';
import draggable from 'vuedraggable';

import { GamesFilterStore } from '@/modules/gamesFilter/gamesFilter.store';
import { FilterStore } from '@/modules/filter/filter.store';
import { gamesService } from '@/modules/games/games.service';
import { GamesStore } from '@/modules/games/games.store';
import GameItem from '@/ui/pages/games-managment/components/GameItem.vue';
import GameSettingsModal from '@/ui/pages/games-managment/components/GameSettingsModal.vue';
import { CommonStore, Modals } from '@/modules/common';

@Component({ components: { GameItem, GameSettingsModal, draggable } })
export default class TagBaseInfo extends Vue {
  form!: WrappedFormUtils;
  isLoading = false;
  debouncedHandleSearch = debounce(this.handleSearch, 500);

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get activeTagId() {
    return GamesStore.activeTagId ?? 0;
  }

  get activeTag() {
    return GamesStore.activeTag;
  }

  get tagName() {
    if (!this.activeTag) return '';
    return this.activeTag?.tag_name;
  }

  get tagPosition() {
    if (!this.activeTag) return '';
    return this.activeTag.position;
  }

  get isTagVisible() {
    if (!this.activeTag) return false;
    return this.activeTag?.is_visible;
  }

  get selectedGames() {
    return GamesStore.selectedGames;
  }

  get allGames() {
    return GamesStore.allGames;
  }

  get gamesByTag() {
    if (!this.activeTagId) return [];
    return GamesStore.gamesByTag;
  }

  get providers() {
    return GamesFilterStore.providers;
  }

  get activeFilters() {
    return FilterStore.activeFilters;
  }

  get modalVisible() {
    return CommonStore.modalVisible;
  }

  get dragOptions() {
    return {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  async mounted() {
    this.isLoading = true;
    await GamesFilterStore.loadData();
    this.isLoading = false;
  }

  @Watch('activeTag')
  async onTagChange() {
    if (this.activeTag) {
      GamesStore.clearGamesByTag();
      await GamesStore.getGamesByTag(this.activeTagId);
      GamesStore.setSelectedGames();
      this.form.resetFields();
    } else {
      GamesStore.resetSelectedGames();
    }
  }

  successMessage(description: TranslateResult) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description as string,
    });
  }

  async createNewTag() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue() as object;
    const [err] = await to(
      GamesStore.createTag({
        ...this.activeFilters,
        ...formData,
        games_ids: gamesService.mapGamesIds(this.selectedGames),
      })
    );
    if (err) return;
    this.form.resetFields();
    this.successMessage(this.$t('games.tagCreatedSuccess'));
    GamesStore.resetSelectedGames();
    this.$emit('tagsChanged');
  }
  async editTag() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    const formData = this.form.getFieldsValue() as object;
    const [err] = await to(
      GamesStore.editExistingTag({
        ...formData,
        games_ids: gamesService.mapGamesIds(this.selectedGames),
      })
    );
    if (err) return;
    this.form.resetFields();
    GamesStore.clearActiveTag();
    this.successMessage(this.$t('games.tagEditSuccess'));
    GamesStore.resetSelectedGames();
    this.$emit('tagsChanged');
  }

  async onDelete() {
    const [err] = await to(GamesStore.deleteTag(this.activeTagId));
    if (err) return;
    this.successMessage(this.$t('games.tagDeleteSuccess'));
    this.$emit('tagsChanged');
  }

  async handleSearch(event: any) {
    await GamesStore.clearAllGames();
    await GamesStore.getAllGames({
      ...this.activeFilters,
      game_name: event.target.value,
      limit: 50,
      offset: 0,
    });
  }
  toggleModal() {
    CommonStore.toggleModal(Modals.GAME_SETTINGS);
  }
}
