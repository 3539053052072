
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICellRendererParams } from '@ag-grid-community/core';

interface ToggleCellParams extends ICellRendererParams {
  toggleFunction: (data: any) => void;
  booleanKey: string;
}

@Component
export default class FavoriteToggle extends Vue {
  params: ToggleCellParams | undefined;

  private async toggle(event: MouseEvent) {
    event.stopPropagation();
    if (this.params) {
      this.params.toggleFunction(this.params.data);
    }
  }
}
