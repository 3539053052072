
import { Mixins, Component, Watch } from 'vue-property-decorator';
import to from 'await-to-js';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { gamesFilterService } from '@/modules/gamesFilter/gamesFilter.service';
import { WithFilters } from '@/ui/mixins/WithFilters';
import { FilterConfig } from '@/modules/filter/filter.types';
import { GamesStore } from '@/modules/games/games.store';
import { TableContainer } from '@/ui/components/TableContainer';
import GamesFilter from '@/ui/components/GamesFilter';
import { GamesFilterStore } from '@/modules/gamesFilter/gamesFilter.store';
import { GameTag, MobileTag } from '@/modules/games/games.types';
import isEqual from 'lodash/isEqual';
import FavoriteToggle from '@/ui/components/CellRenderers/FavoriteToggle.vue';
import TagManager from './components/TagManager.vue';
import ButtonCell from '@/ui/components/CellRenderers/ButtonCell.vue';
import { SELECT_TAG_TYPE } from '@/modules/filter/filter.constants';
import { FilterStore } from '@/modules/filter/filter.store';

@Component({
  components: {
    TableContainer,
    GamesFilter,
    FavoriteToggle,
    TagManager,
    ButtonCell,
  },
})
export default class GamesManagment extends Mixins(WithFilters, WithDataTable) {
  lastSelectedRowNode = {};

  filterConfig: FilterConfig = gamesFilterService.createFilterTags(
    this.providers,
    SELECT_TAG_TYPE,
    this.allGameTypeList
  );

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('games.position'),
          rowDrag: true,
          lockPosition: true,
          width: 100,
        },
        {
          headerName: this.$t('games.tagName'),
          field: 'tag_name',
          flex: 1,
        },
        {
          headerName: this.$t('common.delete'),
          cellRendererFramework: 'ButtonCell',
          cellRendererParams: {
            onClick: this.deleteTag,
            buttonText: this.$t('common.delete'),
            buttonType: 'danger',
            showPopconfirm: true,
          },
          width: 100,
        },
        {
          headerName: this.$t('games.visible'),
          field: 'is_visible',
          cellRendererFramework: 'FavoriteToggle',
          cellRendererParams: {
            toggleFunction: this.toggleIsVisible,
            booleanKey: 'is_visible',
          },
          width: 100,
        },
      ],
      rowData: this.tableData,
      rowClassRules: {
        'row-active': ({ rowIndex }: any) => rowIndex === this.activeIndex,
      },
      animateRows: true,
      rowDragManaged: true,
    };
  }

  get isInfinite() {
    return false;
  }

  get tableData() {
    return GamesStore.tagsArray;
  }

  get totalRecords() {
    return GamesStore.totalTags;
  }

  get canFetchData() {
    return !GamesFilterStore.isLoading;
  }

  get activeIndex() {
    return GamesStore.activeIndex;
  }

  get providers() {
    return gamesFilterService.formatProviderForDropdown(GamesFilterStore.providers);
  }

  get allGameTypeList() {
    return gamesFilterService.formatGameTypes(GamesStore.allGameTypeList);
  }

  async mounted() {
    await GamesStore.getAllGameTypes();
    await GamesFilterStore.loadData();
    this.filterConfig = gamesFilterService.createFilterTags(
      this.providers,
      SELECT_TAG_TYPE,
      this.allGameTypeList
    );
    FilterStore.init(this.filterConfig);
    this.gridApi?.sizeColumnsToFit();
  }

  beforeDestroy() {
    GamesStore.clearStore();
    GamesStore.clearTags();
    GamesStore.resetSelectedGames();
    FilterStore.resetStore();
  }

  @Watch('activeFilters')
  onWatch(newValue: any, oldValue: any) {
    if (isEqual(oldValue, newValue)) return;
    GamesStore.clearStore();
    this.fetchData({ mewValue: newValue.mobile_tag, oldValue: oldValue.mobile_tag });
    // this.gridApi?.setDatasource(this.dataSource);/
    // this.gridApi?.showLoadingOverlay();
  }

  async fetchData(mobileTag: MobileTag) {
    await GamesStore.getAllGames({
      limit: 50,
      offset: this.offset,
      ...this.activeFilters,
    });
    if (mobileTag.mewValue === mobileTag.oldValue) return;
    GamesStore.clearTags();
    await GamesStore.getTags({
      limit: 50,
      offset: this.offset,
      ...this.activeFilters,
    });
    this.gridApi?.hideOverlay();
  }

  successMessage(description: string) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: description,
    });
  }

  errorMessage(description: string = this.$t('genericError.description') as string) {
    this.$notification.error({
      message: this.$t('common.error') as string,
      description: description,
    });
  }

  async toggleIsVisible(tag: GameTag) {
    const [err] = await to<any>(GamesStore.toggleTagVisibility(tag));
    if (err) return this.errorMessage();
    this.successMessage(this.$t('games.toggleVisibilitySuccess') as string);
  }

  async deleteTag(tag: GameTag) {
    const [err] = await to(GamesStore.deleteTag(tag.id));
    if (err) return this.errorMessage();
    this.successMessage(this.$t('games.tagDeleteSuccess') as string);
    GamesStore.clearActiveTag();
    GamesStore.setActiveIndex(-1);
    this.gridApi?.redrawRows();
  }

  onRowClicked({ data, node, rowIndex }: any): void {
    if (rowIndex === this.activeIndex) return;
    GamesStore.setActiveIndex(rowIndex);
    GamesStore.setActiveTag(data.id);
    this.gridApi?.redrawRows();
    this.lastSelectedRowNode = node;
  }

  async onRowDragEnd({ node }: any) {
    const newPositions = node.parent.childrenAfterSort.map((node: any) => {
      return {
        position: node.rowIndex + 1,
        id: node.data.id,
      };
    });

    const [err] = await to<any>(GamesStore.editTagsPosition(newPositions));
    if (err) return this.errorMessage();
    GamesStore.updateTagsAfterChange(newPositions);
    GamesStore.setActiveIndex(node.rowIndex);
    this.gridApi?.redrawRows();
    this.successMessage(this.$t('games.tagPositionChangeSuccess') as string);
  }

  destroyed() {
    GamesStore.clearStore();
  }

  tagsChanged() {
    GamesStore.setActiveIndex(-1);
    GamesStore.clearActiveTag();
    this.gridApi?.redrawRows();
  }
}
