
import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';

import { CommonStore, Modals } from '@/modules/common';
import { GamesStore } from '@/modules/games/games.store';

@Component({
  name: 'GameItem',
})
export default class GameItem extends Vue {
  isChecked = false;
  defaultImage = '/default.svg';
  @Prop({ required: true })
  item: any;

  @Prop({ required: true })
  direction!: string;

  toggleModal(id: number) {
    GamesStore.setGameForEdit(id);
    CommonStore.toggleModal(Modals.GAME_SETTINGS);
  }

  get gameImage() {
    return this.item.image ? this.item.image : this.defaultImage;
  }
}
