var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{class:_vm.$style.gameSettingsModal,attrs:{"width":900,"visible":_vm.visible,"title":_vm.gameTitle},on:{"ok":_vm.onSave,"cancel":_vm.handleClose}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":32}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.minBet')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'min_bet',
              {
                initialValue: _vm.gameForEdit.min_bet,
              } ]),expression:"[\n              'min_bet',\n              {\n                initialValue: gameForEdit.min_bet,\n              },\n            ]"}],attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.maxBet')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'max_bet',
              {
                initialValue: _vm.gameForEdit.max_bet,
              } ]),expression:"[\n              'max_bet',\n              {\n                initialValue: gameForEdit.max_bet,\n              },\n            ]"}],attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.lines')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'lines',
              {
                initialValue: _vm.gameForEdit.lines,
              } ]),expression:"[\n              'lines',\n              {\n                initialValue: gameForEdit.lines,\n              },\n            ]"}],attrs:{"min":0}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.reels')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'reels',
              {
                initialValue: _vm.gameForEdit.reels,
              } ]),expression:"[\n              'reels',\n              {\n                initialValue: gameForEdit.reels,\n              },\n            ]"}],attrs:{"min":0}})],1)],1),_c('a-col',{attrs:{"span":16}},[_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.image')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'image',
              {
                initialValue: _vm.gameForEdit.image,
              } ]),expression:"[\n              'image',\n              {\n                initialValue: gameForEdit.image,\n              },\n            ]"}]}),_c('div',{staticClass:"imageWrapper"},[_c('img',{attrs:{"width":"100%","src":_vm.gameImage,"alt":"game image"},on:{"error":function($event){$event.target.src = '/default.svg'}}})])],1),_c('a-form-item',{attrs:{"label":_vm.$t('casinoGame.imagePreview')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'image_preview',
              {
                initialValue: _vm.gameForEdit.image_preview,
              } ]),expression:"[\n              'image_preview',\n              {\n                initialValue: gameForEdit.image_preview,\n              },\n            ]"}]}),_c('div',{staticClass:"imageWrapper"},[_c('img',{attrs:{"width":"100%","src":_vm.imagePreview,"alt":"game image"},on:{"error":function($event){$event.target.src = '/default.svg'}}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }