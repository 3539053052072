
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import to from 'await-to-js';

import { GamesStore } from '@/modules/games/games.store';
import { EditGameSettings } from '@/modules/games/games.types';

@Component({ name: 'GameSettingsModal' })
export default class GameSettingsModal extends Vue {
  form!: WrappedFormUtils;
  defaultImage = '/default.svg';

  @Prop({ required: true })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  get gameForEdit() {
    return GamesStore.gameForEdit as EditGameSettings;
  }

  get gameImage() {
    return this.gameForEdit.image ? this.gameForEdit.image : this.defaultImage;
  }

  get imagePreview() {
    return this.gameForEdit.image_preview ? this.gameForEdit.image_preview : this.defaultImage;
  }

  handleClose() {
    this.close();
  }

  onSuccess(formData: EditGameSettings) {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('casinoGame.gameSaved') as string,
    });
    GamesStore.updateGameSettings(formData);
  }

  async onSave() {
    if (!this.form.isFieldsTouched()) return this.close();
    const formData = this.form.getFieldsValue() as EditGameSettings;
    const [err] = await to(GamesStore.editGameSettings(formData));
    if (err) return;

    this.onSuccess(formData);
    this.close();
  }

  gameTitle() {
    return `${this.$t('casinoGame.settings')} - ${this.gameForEdit.game_name}`;
  }
}
