
import { GamesStore } from '@/modules/games/games.store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import TagBaseInfo from './TagBaseInfo.vue';

@Component({
  components: { TagBaseInfo },
})
export default class TagManager extends Vue {
  @Prop({ required: true })
  tagsChanged!: () => {};

  get activeTag() {
    return GamesStore.activeTag;
  }

  get tagName() {
    if (!this.activeTag) return '';
    return this.activeTag.tag_name;
  }
}
