
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { GamesFilterStore } from '@/modules/gamesFilter/gamesFilter.store';
import GamesFilterItem from './GamesFilterItem.vue';

@Component({ components: { GamesFilterItem } })
export default class GamesFilter extends Vue {
  get providers() {
    return GamesFilterStore.providers;
  }

  get isLoading() {
    return GamesFilterStore.isLoading;
  }

  get activeFiltersCount() {
    return !!GamesFilterStore.activeFilters.length;
  }

  get toggle() {
    return (item: any) => GamesFilterStore.updateItem(item);
  }

  mounted() {
    GamesFilterStore.loadData();
  }
}
