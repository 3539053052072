
import { CommonStore } from '@/modules/common';
import Vue from 'vue';
import { Prop, Component, Watch } from 'vue-property-decorator';

@Component
export default class GamesFilterItem extends Vue {
  payload = {};

  @Prop({ required: true })
  item: any;

  @Prop({ required: true })
  onCheckboxClick!: Function;

  @Watch('item', { immediate: true, deep: true })
  onWatch() {
    this.payload = { ...this.item };
  }

  get isDisabled() {
    return CommonStore.isFetching;
  }

  get itemNextState() {
    return !this.item.isSelected;
  }

  mounted() {
    this.payload = { ...this.item };
  }

  handleCheck(event: MouseEvent) {
    event.stopPropagation();
    this.onCheckboxClick(this.payload);
  }
}
