import { render, staticRenderFns } from "./TagBaseInfo.vue?vue&type=template&id=6201383c&"
import script from "./TagBaseInfo.vue?vue&type=script&lang=tsx&"
export * from "./TagBaseInfo.vue?vue&type=script&lang=tsx&"
import style0 from "./TagBaseInfo.vue?vue&type=style&index=0&module=true&lang=less&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports